<template>
<div class="wrapper_main">
	<main class="content">
		<div class="headline">
			<h1 class="headline__title">{{ $translate('add-a-product') }}</h1>
			<p class="headline__text">{{ $translate('fill-in-information-to-add-new-product') }}</p>
		</div>
		<div class="tabs-wrapper">
			<ul class="tabs">
				<li class="tabs__item tutorial_tooltip_fs13" v-for="(item, itemIndex) in tabsArray" :key="item.value" :class="[item.class ? item.class : '', itemIndex === 0 ? 'tutorial_tooltip_bottom_left' : 'tutorial_tooltip_bottom_center']">
					<a class="nav-tab" @click="switchTab(item.value)" :class="isActiveTab(item.value)">
						{{ item.title }}
					</a>
				</li>
			</ul>
		</div>
		<div class="content__body">
			<div class="tab-content">
				<div id="info" class="tab-content__item tutorial_tooltip_1 tutorial_tooltip_top_right" :class="isActiveTab('info')">
					<div class="wizard-item footer-fixed">
						<div class="items-group cards ">
							<div class="cards__col-6 mt-15">
								<div class="el-form">
									<label class="el-form__title">{{ $translate('category') }}</label>
									<div class="flex items-center">
										<DropdownAdvanced 
											:options="categories.length ? categories.map(c => ({ id: c.id, name: c.name })) : [{ id:'Add a new category', name: 'Add a new category' }]"
											v-model="categoryProductsId"
										/>
									</div>
								</div>
							</div>
						</div>	
						<div class="items-group cards ">	
							<div class="cards__col-6">
								<div class="el-form mt-15 flex-auto">
									<label class="el-form__title">{{ $translate('product-name') }}*</label>
									<input type="text" class="input" v-model="product.title">
								</div>
							</div>
							<div class="cards__col-6 mt-15">
								<div class="select-group">
									<div class="el-form flex-auto">
										<label class="el-form__title">{{ $translate('quantity') }}*</label>
										<input type="number" class="input" v-model="product.quantity">
									</div>
									<div class="el-form flex-auto" style="min-width: 125px;">
										<label class="el-form__title">Type</label>
										<!-- <input type="text" class="input" v-model="product.units"> -->
										<DropdownAdvanced 
											:options="[
												{ groupTitle: 'Weight:' },
												{ id: 'Kg', name: 'Kg' },
												{ id: 'g', name: 'g' },
												{ id: 'mg', name: 'mg' },
												{ groupTitle: 'Liquids:' },
												{ id: 'L', name: 'L' },
												{ id: 'dl', name: 'dl' },
												{ id: 'cl', name: 'cl' },
												{ id: 'ml', name: 'ml' },
											]"
											v-model="product.units"
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="el-form mt-15">
							<label class="el-form__title">{{ $translate('description') }}*</label>
							<textarea class="textarea" placeholder="Write product description here..." v-model="product.description"></textarea>
						</div>
						
						<!--   Media   -->
						<div class="el-form item-body border-top mt-15">
							<label class="el-form__title mt-15" style="display: block;">{{ $translate('media') }}</label>
							<div class="gallery" v-if="images.length">
								<div class="gallery__item" v-for="(m, index) in images" :key="m.id">
									<img :src="m">
									<button class="delete-gallery__item btn" @click.prevent="deleteMediaFromAdded(index)">
										<inline-svg :src="require(`@/assets/img/x.svg`)"/>
									</button>
								</div>
							</div>
							<div 
								class="fake-dropzone" 
								:class="{ 'drop-ready': dragState }" 
								@drop.prevent.stop="dropMedia"  
								@dragenter.prevent @dragover.prevent
							>
								<label>
									<img src="@/assets/img/upload-image-icon.svg">
									<span>{{ $translate('drag-&-drop-or-browse-to-choose-a-file') }}</span>
									<!-- @change="uploadMedia" -->
									<input type="file" accept="image/*" multiple @change="setProductMediaFiles" ref="dropMediaInput">
								</label>
							</div>
						</div>
					</div>
				</div>
				<div id="detailed-info" class="tab-content__item" :class="isActiveTab('detailed-info')">
					<div class="wizard-item footer-fixed">
						<div class="items-group cards">
							<div class="cards__col-6">
								<div class="el-form">
									<label class="el-form__title">{{ $translate('serial-number') }}</label>
									<input type="text" class="input" v-model="product.serial_number">
								</div>
							</div>
							<div class="cards__col-6">
								<div class="el-form">
									<label class="el-form__title">{{ $translate('manufacturing-date') }}</label>
									<label class="datepicker-el">
										<DatePicker v-model="product.manufacturing_date"/>
									</label>
								</div>
							</div>
							<div class="cards__col-6 mt-15">
								<div class="el-form">
									<label class="el-form__title">{{ $translate('expiry-date') }}</label>
									<label class="datepicker-el">
										<DatePicker v-model="product.expiry_date"/>
									</label>
								</div>
							</div>
						</div>
						<div class="items-group cards border-top mt-15">
							<div class="cards__col-6 mt-15">
								<div class="el-form">
									<label class="el-form__title">{{ $translate('invoice-number') }}</label>
									<input type="text" class="input" placeholder="12345" v-model="product.invoice_number">
								</div>
							</div>
							<div class="cards__col-6 mt-15">
								<div class="el-form">
									<label class="el-form__title">{{ $translate('purchase-order') }}</label>
									<input type="text" class="input" placeholder="PO-12345" v-model="product.purchase_order">
								</div>
							</div>
						</div>
						<div class="items-group cards border-top mt-15">
							<!-- <div class="cards__col-6 mt-15">
								<div class="el-form">
									<label class="el-form__title">{{ $translate('rfid-number') }}</label>
									<input type="text" class="input" placeholder="E0040100019D0CA1" v-model="product.rfid_number">
								</div>
							</div> -->
							<div class="cards__col-6 mt-15">
								<div class="el-form">
									<label class="el-form__title">{{ $translate('batch-id') }}</label>
									<input type="text" class="input" placeholder="ARW004" v-model="product.batch_id">
								</div>
							</div>
						</div>
					</div>
				</div>
				<div id="location" class="tab-content__item" :class="isActiveTab('location')">
					<div class="tab-content__block footer-fixed">
						<!-- <div class="map" style="border-radius: var(--border-radius-7); overflow: hidden;">
							<div id="product-map" style="width: 100%; height: 410px"></div>
							<img src="@/assets/img/map-big.jpg" style="width: 100%;">
						</div> -->
						<div class="map search-map" style="border-bottom-left-radius: var(--border-radius-7); border-bottom-right-radius: var(--border-radius-7); overflow: hidden;">
							<div class="input-with-suggestions-wrpr">
								<a href="#" style="position: absolute;left: 7px;top: 5px;width: 30px;height: 30px;" @click.prevent="updateProductDataMethod"></a>
								<input type="text" style="z-index:2"
									placeholder="USA, New York, New York" 
									class="input bg-left search-icon"
									name="location"
									v-model="product.location.address"
									@change="updateProductDataMethod"
								>
								<ul v-if="showSuggestions">
									<li v-for="(locationItem, locationItemIndex) in suggestionsLocations" :key="locationItem.label+'-'+locationItemIndex">
										<a href="#" @click.prevent="setLocation({ lat: locationItem.latitude, lng: locationItem.longitude }, true)">
											{{ locationItem.label }}
										</a>
									</li>
								</ul>
							</div>
							<div id="product-map" style="width: 100%; height: calc(100vh - 300px);"></div>
						</div>
					</div>
				</div>
				<div id="device" class="tab-content__item" :class="isActiveTab('device')">
					<div class="addition footer-fixed">
						<div class="headline-wrapper flex items-center">
							<div class="headline headline--sm flex-auto">
								<h2 class="headline__title">{{ $translate('assign-devices') }}</h2>
								<p class="headline__text">{{ $translate('here-you-can-add-a-device-monitoring-your-product') }}</p>
							</div>
							<!-- <WhitePlusButton @click="addDeviceOpen">{{ $translate('add-device') }}</WhitePlusButton> -->
						</div>
						<ul class="editable-list">
							<li class="editable-list__item" v-for="d in fieldValues('devices')" :key="d.id" >
								<div class="data-row">
									<ul class="data-row__list">
										<li class="data-row__item">{{d.name}}</li>
										<li class="data-row__item">
											<span class="data-row__icon">
												<DeviceIcon :category="d.category"></DeviceIcon>
											</span> 
											{{d.category}}
										</li>
										<li class="data-row__item">Italy, Rome</li>
									</ul>
									<div class="data-row__controls">
										<button type="button" class="btn btn-icon red-color"  @click="deleteFieldItem('devices',d.id)">
											<inline-svg :src="require(`@/assets/img/Delete-circle.svg`)"/>
										</button>
									</div>
								</div>
							</li>
						</ul>
						<WhitePlusButton  @click="addDeviceOpen">{{ $translate('add-device') }}</WhitePlusButton>
					</div>
				</div>
				<div id="parent" class="tab-content__item" :class="isActiveTab('parent')">
					<div class="addition footer-fixed">
						<div class="headline-wrapper flex items-center">
							<div class="headline headline--sm flex-auto tutorial_tooltip_parent_2 tutorial_tooltip_top_left">
								<h2 class="headline__title">{{ $translate('parent-products') }}</h2>
								<p class="headline__text">{{ $translate('here-you-can-add-a-parent-product-to-this-product') }}</p>
							</div>
							<!-- <WhitePlusButton @click="addParentProductOpen">{{ $translate('add-product') }}</WhitePlusButton> -->
						</div>
						<ul class="editable-list">
							<li class="editable-list__item" v-for="p in product.parent_products" :key="p.id">
								<div class="data-row">
									<ul class="data-row__list">
										<li class="data-row__item">Name: {{getProductName(p.id)}}</li>
										<li class="data-row__item">Type of event: {{eventTypes[p.type] || p.type}}</li>
										<li v-if="getProductFullObject(p.id).quantity">
											Quantity: <span style="text-transform:uppercase;">{{ getProductFullObject(p.id).quantity || '0' }} {{ getProductFullObject(p.id).units }}</span>
										</li>
									</ul>
									<div class="data-row__controls">
										<button type="button" class="btn btn-icon" @click="editParentProduct(p)">
											<inline-svg :src="require(`@/assets/img/ico-gear.svg`)"/>
										</button>
										<button type="button" class="btn btn-icon red-color" @click="deleteParentProducts(p.id)">
											<inline-svg :src="require(`@/assets/img/Delete-circle.svg`)"/>
										</button>
									</div>
								</div>
							</li>
						
						</ul>
						<WhitePlusButton @click="addParentProductOpen">{{ $translate('add-product') }}</WhitePlusButton>

					</div>
				</div>
				<div id="farm" class="tab-content__item" :class="isActiveTab('farm')">
					<div class="addition footer-fixed">
						<div class="headline-wrapper flex items-center">
							<div class="headline headline--sm flex-auto">
								<h2 class="headline__title">{{ $translate('link-to-a-farm') }}</h2>
								<p class="headline__text">{{ $translate('here-you-can-link-farm-items-to-a-product') }}</p>
							</div>
							<div class="buttons-group add-buttons">
								<WhitePlusButton @click="paddocksPopupOpen('herd')">{{ $translate('add-herd') }}</WhitePlusButton>
								<WhitePlusButton @click="paddocksPopupOpen('building')">{{ $translate('add-building') }}</WhitePlusButton>
								<WhitePlusButton @click="paddocksPopupOpen('field')">{{ $translate('add-field') }}</WhitePlusButton>
								<WhitePlusButton @click="paddocksPopupOpen('paddocks')">{{ $translate('add-paddock') }}</WhitePlusButton>
							</div>
						</div>
						<ul class="editable-list" v-click-outside="farmLinkMenuOpen">
							<template v-if="selectedPaddocks.length">
								<li class="editable-list__item" v-for="paddockItem in selectedPaddocks" :key="paddockItem.id">
									<div class="data-row">
										<ul class="data-row__list">
											<li class="data-row__item">Field name: {{ paddockItem.basic_info.name }}</li>
											<li class="data-row__item" style="text-transform: capitalize;">
												Total area: {{ 
													paddockItem.type !== 'herd' && paddockItem.basic_info.total_area ? paddockItem.basic_info.total_area : 
													paddockItem.type === 'herd' ? paddockItem.basic_info.type : paddockItem.type
												}}
											</li>
											<li class="data-row__item" style="text-transform: capitalize;" v-if="paddockItem.basic_info.plots_config && paddockItem.basic_info.plots_config.crop">
												Crop: {{ paddockItem.basic_info.plots_config.crop }}
											</li>
											<li class="data-row__item" style="text-transform: capitalize;" v-if="paddockItem.basic_info.plots_config && paddockItem.basic_info.plots_config.variety">
												Variety: {{ paddockItem.basic_info.plots_config.variety }}
											</li>
										</ul>
										<div class="data-row__controls">
											<div class="relative" style="margin-right: 15px;">
												<a class="btn btn-icon" @click="farmLinkMenuOpen(paddockItem.id)">
													<inline-svg :src="require(`@/assets/img/ico-gear.svg`)"/>
												</a>
												<div 
													class="categories__dropdown dropdown-content" 
													:class="{ 'active':farmLinkMenu === paddockItem.id }" style="right: 0;left: auto;"
												>
													<ul>
														<li>
															<router-link :to="{ path: '/farm-map', query: { 'edit': paddockItem.id } }">
																{{ $translate('view-farm-item') }}
															</router-link>
														</li>
														<li>
															<router-link :to="{ path: '/farm-map', query: { 'edit': paddockItem.id } }">
																{{ $translate('edit-farm-item') }}
															</router-link>
														</li>
													</ul>
												</div>
											</div>
											<button type="button" class="btn btn-icon red-color" @click="paddockItemRemove(paddockItem.id)">
												<inline-svg :src="require(`@/assets/img/Delete-circle.svg`)"/>
											</button>
										</div>
									</div>
								</li>
							</template>
							<li v-if="!selectedPaddocks.length">
								<!-- <p>{{ $translate('please-link-farm-items-to-a-product') }}</p> -->
							</li>
						</ul>
					</div>
				</div>
				<div id="code-settings" class="tab-content__item" :class="isActiveTab('code-settings')"> 
					<div class="wizard-item footer-fixed" v-if="activeTab === 'code-settings'">
						<div class="headline headline--sm mt-15">
							<div class="flex items-center" style="margin-bottom: 10px;">
								<h2 class="headline__title" style="margin: 0;">{{ $translate('company-data') }}</h2>
								<label class="module__check ml-10">
									<input type="checkbox" v-model="companyDataAllSelected" @change="allCheckboxDataChange('companyData', 'companyDataAllSelected')">
									<span class="check"></span>
								</label>
							</div>
							<p class="headline__text">{{ $translate('select-info-that-you-want-to-show-in-qr-code') }}</p>
						</div>
						<div class="cards flex tutorial_tooltip_code-settings_1 tutorial_tooltip_right_center"> 
							<div class="cards__col-4 cards__col-md-6 mt-15 flex flex-col" v-for="colNumber in [0,1,2]" :key="colNumber" >
								<label class="module__check" v-for="(inputKey, index) in getCell(QRSettings.companyData,colNumber)" :key="inputKey" :class="index>0?'mt-15':null">
									<input type="checkbox" name="privacy_policy" v-model="QRSettings.companyData[inputKey]" @change="checkCheckedInputs">
									<span class="check"></span>
									<span class="text" >
										<span class="input-text" >
											{{inputKey.replaceAll("_"," ")}}
										</span>
									</span>
								</label>
							</div>
						</div>
						<div class="cards items-group border-top mt-15">
							<div class="cards__col">
								<div class="headline-wrapper flex items-center">
									<div class="headline headline--sm flex-auto mt-15">
										<h2 class="headline__title">{{ $translate('assign-devices') }}</h2>
										<p class="headline__text">{{ $translate('here-you-can-find-devices-assigned-to-your-product') }}</p>
									</div>
									<WhitePlusButton @click="addDeviceOpen">{{ $translate('add-devices') }}</WhitePlusButton>
								</div>
								<ul class="editable-list">
									<li class="editable-list__item" v-for="d in fieldValues('devices')" :key="d.id">
										<div class="data-row flex-col has-spoiler">
											<div class="data-row__head flex w-full">
												<ul class="data-row__list">
													<li class="data-row__item">{{d.name}}</li>
													<li class="data-row__item" v-if="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id) && QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObeservedOptions">
														<span class="data-row__icon" v-if="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObserved">
															<DeviceIcon :category="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObserved" />
														</span> 
														<!-- {{ d.category }} -->
														<DropdownAdvanced
															:options="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObeservedOptions"
															v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObserved"
														/>
													</li>
													<li class="data-row__item" v-if="d.location">{{ d.location.address }}</li>
												</ul>
												<div class="data-row__controls" >
													<button type="button" class="btn btn-icon red-color" @click="deleteFieldItem('devices',d.id)">
														<inline-svg :src="require(`@/assets/img/Delete-circle.svg`)"/>
													</button>
												</div>
											</div>
											<div class="data-row__spoiler spoiler" v-if="QRSettings.deviceData.length && QRSettings.deviceData.map(itemM => itemM.deviceID).includes(d.id)">
												<div class="spoiler__button flex items-center" @click="opedOptions">
													<span class="flex-auto pointer-events-none">{{ $translate('device-info') }}</span>
													<inline-svg class="pointer-events-none" :src="require(`@/assets/img/Arrow-down.svg`)"/>
												</div>
												<div class="spoiler__body flex flex-col">
													<label class="module__check">
														<input type="checkbox" name="privacy_policy" v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).atDayCreation">
														<span class="check"></span>
														<span class="text">
															{{ devCats.find(itemF => itemF.id === QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObserved).name }} {{ $translate('at-the-day-of-product-creation') }}
														</span>
													</label>
													<label class="module__check mt-15">
														<input type="checkbox" name="privacy_policy" v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).duringDayCreation">
														<span class="check"></span>
														<span class="text">
															{{ devCats.find(itemF => itemF.id === QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObserved).name }} {{ $translate('during-the-day/week/month-of-the-product-creation') }}
														</span>
													</label>
													<label class="module__check mt-15">
														<input type="checkbox" name="privacy_policy" v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).duringSmartCondition">
														<span class="check"></span>
														<span class="text">
															{{ devCats.find(itemF => itemF.id === QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObserved).name }} {{ $translate('during-the-smart-condition-in-the-dispatch') }}
														</span>
													</label>
													<label class="module__check mt-15">
														<input type="checkbox" name="privacy_policy" v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).maxMinValue.checked">
														<span class="check"></span>
														<span class="text">{{ $translate('max/min-value-of-the') }} <span style="text-transform: lowercase;margin: 0 5px;">
															{{ devCats.find(itemF => itemF.id === QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObserved).name }}
														</span> {{ $translate('during-a-time-range') }}</span>
													</label>
													<div class="cards flex" style="max-width: 630px;">
														<div class="cards__col-6 mt-15">
															<div class="buttons-group">
																<DatePicker v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).maxMinValue.minDate"></DatePicker>
																<TimePicker v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).maxMinValue.minTime"></TimePicker>
															</div>
														</div>
														<div class="cards__col-6 mt-15">
															<div class="buttons-group">
																<DatePicker v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).maxMinValue.maxDate"></DatePicker>
																<TimePicker v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).maxMinValue.maxTime"></TimePicker>
															</div>
														</div>
													</div>
													<label class="module__check mt-15">
														<input type="checkbox" name="privacy_policy"  v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).exactValue.checked" exactValue>
														<span class="check"></span>
														<span class="text">{{ $translate('exact-value-of-the') }} <span style="text-transform: lowercase;margin: 0 5px;">
															{{ devCats.find(itemF => itemF.id === QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObserved).name }}
														</span> {{ $translate('during-a-time-range') }}</span>
													</label>
													<div class="cards flex" style="max-width: 630px;">
														<div class="cards__col-6 mt-15">
															<div class="buttons-group">
																<DatePicker v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).exactValue.firstDate"></DatePicker>
																<TimePicker v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).exactValue.firstTime"></TimePicker>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
						<div class="cards items-group border-top mt-15">
							<div class="cards__col">
								<div class="headline headline--sm mt-15">
									<div class="flex items-center" style="margin-bottom: 10px;">
										<h2 class="headline__title" style="margin: 0;">{{ $translate('product-chain-data') }}</h2>
										<label class="module__check ml-10">
											<input type="checkbox" v-model="productChainDataAllSelected" @change="allCheckboxDataChange('productData', 'productChainDataAllSelected')">
											<span class="check"></span>
										</label>
									</div>
									<p class="headline__text">{{ $translate('select-info-that-you-want-to-show-in-qr-code') }}</p>
								</div>								
								<div class="cards flex">
									<template v-for="inputKey in Object.entries(QRSettings.productData)">
										<div 
											class="cards__col-4 cards__col-md-6" :key="inputKey[0]"
											v-if="inputKey[0] !== 'transaction_history' && inputKey[0] !== 'device_rule_log'"
										>
											<label class="module__check mt-15">
												<input type="checkbox" name="privacy_policy" v-model="QRSettings.productData[inputKey[0]]" @change="checkCheckedInputs">
												<span class="check"></span>
												<span class="text" >
													<span class="input-text" >
														{{inputKey[0].replaceAll("_"," ")}}
													</span>
												</span>
											</label>
										</div>
									</template>
								</div>
							</div>
						</div>
						<div class="cards items-group border-top mt-15">
							<div class="cards__col">
								<div class="cards flex">
									<div class="cards__col-4 cards__col-md-6">
										<label class="module__check mt-15">
											<input type="checkbox" v-model="actions_info">
											<span class="check"></span>
											<span class="text">
												<span class="input-text">{{ $translate('actions-info') }}</span>
											</span>
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>

	<FooterTabsControl
		:previous="true"
		cancelRoute="/products"
		:confirmName="$translate('create-a-product')"
		@confirm="createProduct"
		@swipeTab="swipeTab"
		:pageTabs="pageTabs"
		:activeTab="activeTab"
		:processing="processing"
	/>
	<!-- brfore translate confirmName="Create A Product" -->

	<AddParentProduct
		v-if="addParentProductsPopupInfo.show"
		:products="addParentProductsPopupInfo.products"
		:product="addParentProductsPopupInfo.product"
		@close="addParentProductsClose"
		@submit="addParentProductsSubmit"
    />

	<AddDevice
		v-if="addDevicesPopupInfo.show"
		:devices="addDevicesPopupInfo.devices"
		@close="addDeviceClose"
		@submit="addDeviceSubmit"
		:title="$translate('assign-devices-to-product')"
	/>
	<!-- before translate title="Assign Device(s) to product" -->

	<ChangePaddockBuildingPopup
        v-if="paddocksPopup.show"
        :multiple="true"
        :paddocks="paddocksPopup.paddocks"
        @close="paddocksPopupClose"
        @submit="submitPaddocksPopup"
        :title="$translate('link-to-a-farm')"
        :value="product.paddocks"
    />
	<!-- before translate title="Link to a Farm" -->
	
	<SuccessAction
		v-if="successAddPopup"
		@close="successAddPopup=false"
		to="/products"
		:message="$translate('product-added-successfully!')"
	/>
	<!-- before translate :message="'Product added successfully!'" -->

	<div class="popup_wrpr danger-message" v-if="mediaPopup">
		<div class="popup_overlay" @click="deleteMediaPopupClose"></div>
		<div class="popup__window">
			<div class="popup__window-content">
				<div class="popup__window-header">
					<h2>{{ $translate('delete') }} {{ deleteMediaType }}</h2>
					<button type="button" class="popup__closer" @click="deleteMediaPopupClose">
						<inline-svg :src="require('@/assets/img/close-icon.svg')"/>
					</button>
				</div>
				<div class="popup__window-body">
					<p class="popup__message">
						{{ $translate('do-you-confirm-delete-file?') }}
					</p>
				</div>
				<div class="popup__window-actions">
					<button type="button" class="btn btn-w-shadow btn-standart-2 popup__closer" @click="deleteMediaPopupClose">
						<span>{{ $translate('cancel') }}</span>
					</button>
					<button type="button" class="btn btn-primary btn-standart-2" @click="deleteMediaFile">
						<span>{{ $translate('confirm') }}</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import { API, Storage } from 'aws-amplify';
import {
	createProduct,
	updateProduct,
	updateDevice,
	createProductsCategory,

	createMedia,
	deleteMedia,
} from '@/graphql/mutations';

import {
	getProduct
} from '@/graphql/queries';

import Base from '@/components/base';
import Buttons from '@/components/buttons';
import Popups from '@/components/popups';
import ChangePaddockBuildingPopup from '@/components/popups/ChangePaddockBuildingPopup';
import { compareData } from '@/helpers'
import { mixTabs,mixProcessing,mixArrayFields } from "@/mixins";
import {eventTypes} from "@/config/products"
import { devCats } from '@/config';

export default {
	name: 'SingleProductAdd',
	components: {
		...Base,
		...Buttons,
		...Popups,
		ChangePaddockBuildingPopup,
	},
	mixins:[mixTabs,mixProcessing,mixArrayFields],
	data() {
		return {
			product: {
				title: '',
				status: 'active',
				companyProductsId: null,
				location:{
					lat:null,
					lng:null,
					address:null
				},
				parent_products:[],
				paddocks: [],
			},
			QRSettings:{
				companyData:{
					location:false,
					history_of_company:false,
					media:false,
					documentation:false,
					year_of_foundation:false,
					production_per_year:false,
					production_area:false,
					soils:false,
					cellar_work:false,
					productivity:false,
					certifications:false,
					philosophy:false,
				},
				deviceData: [],
				productData:{
					datetime_creation: false ,
					rules_business_conditions: false,
					datetime_dispatch: false,
					datetime_receive: false,
					datetime_return: false,
					transaction_history: true,
					device_rule_log: true,
					basic_info: false,
					parent_products: false,
					list_of_dispatches: false,
					linked_IOT_devices: false,
					smart_conditions: false
				}
			},
			actions_info: false,
			companyDataAllSelected: true,
            productChainDataAllSelected: true,
			categoryProductsId:null,
			productAssignedDevices:[],
			pageTabs:['info', 'detailed-info', 'location', 'device', 'parent', 'farm', 'code-settings'],
			//config mixArrayFields
			arrayFields:{
                devices:[],
            },
			addParentProductsPopupInfo:{
				show:false,
				products:null,
				product:null
			},
		
			paddocksPopup: {
                show: false,
                paddocks: []
            },
			successAddPopup: false,
			farmLinkMenu: null,
			tabsArray: [
				{ title: 'Basic Info', value: 'info', class: 'tutorial_tooltip_2' },
				{ title: 'Detailed info', value: 'detailed-info', class: 'tutorial_tooltip_3' },
				{ title: 'Location', value: 'location', class: 'tutorial_tooltip_4' },
				{ title: 'Assign Device', value: 'device', class: 'tutorial_tooltip_5' },
				{ title: 'Parent Products', value: 'parent', class: 'tutorial_tooltip_parent_1' },
				{ title: 'Farm', value: 'farm', class: 'tutorial_tooltip_6' },
				{ title: 'QR Code Settings', value: 'code-settings', class: 'tutorial_tooltip_7' },
			],

			media: [],
			deleteMediaTarget: null,
			deleteMediaType: 'media',
			mediaPopup: false,
			dragState: false,
			productMediaFiles: [],
			images: [],
			devCats: devCats,
			suggestionsLocations: [],
			showSuggestions: false,
		}
	},
	
	computed: {
		edit() {
			return this.productID !== 'new'
		},
		companyData() {
			return this.$store.state.companyData || {}
		},
		categories() {
			return this.$store.state.categories || []
		},
		devices(){
			return this.$store.state.devices || []
		},
		products(){
			return this.$store.state.products || []
		},
        paddocks(){
            return this.$store.state.paddocks;
        },
		selectedPaddocks(){
			return this.paddocks.filter(item => this.product.paddocks.includes(item.id));
		},
		eventTypes(){
			return eventTypes
		},
		allProducts(){
			return [ ...this.$store.state.products, ...this.$store.state.receivedProducts ]
		}
	},

	watch: {
    	arrayFields: {
			deep: true,
			handler(){
				this.arrayFields.devices.forEach(async element => {
					let findDevice = this.devices.find(item => item.id === element);
					let deviceConfigId = findDevice && findDevice.config && findDevice.config.device_id ? findDevice.config.device_id : null;
					let parameterObeservedOptions = await this.generateParameterObeserved(deviceConfigId);

					this.QRSettings.deviceData.push({
						deviceID: element,
						parameterObserved: parameterObeservedOptions[0].id,
						parameterObeservedOptions: parameterObeservedOptions,
						atDayCreation:false,
						duringDayCreation:false,
						duringSmartCondition:false,
						maxMinValue:{
							checked:false,
							maxDate:null,
							maxTime:null,
							minDate:null,
							minTime:null
						},
						exactValue:{
							checked:false,
							firstDate:null,
							firstTime:null,
							secondDate:null,
							secondTime:null
						}
					})
				});
			}
		},
		categoryProductsId(value){
			// console.log('categoryProductsId:', value);
			if(value === 'Add a new category'){
				this.$router.push('/categories/add');
			}
		}
	},

	async created() {
		this.product.companyProductsId = this.companyData.id;
		this.checkCheckedInputs();
	},
	async mounted(){
		await this.initMap()
	},

	methods: {
		async generateParameterObeserved(deviceId){
            if(deviceId){
                let parameterObeservedOptions = [];
                let deviceDataFromApi = await this.$provider.centaurAPI.getRealDeviceData(deviceId);

                for (let index = 0; index < deviceDataFromApi.length; index++) {
                    let devCatsFinded = devCats.find(dItem => dItem.centaur_type_id === deviceDataFromApi[index].type);
                    
                    if(devCatsFinded && !parameterObeservedOptions.map(item => item.id).includes(devCatsFinded.id)){
                        parameterObeservedOptions.push({
                            name: devCatsFinded.name,
                            id: devCatsFinded.id
                        });
                    }
                }

				return parameterObeservedOptions;
            } else {
				return null;
			}
        },
		allCheckboxDataChange(qrDataName, checkboxVar){
            let canceledItems = ['transaction_history', 'device_rule_log'];

            Object.entries(this.QRSettings[qrDataName]).map(item => item[0]).forEach(element => {
                if(!canceledItems.includes(element)) this.QRSettings[qrDataName][element] = this[checkboxVar];
            })
        },
        checkCheckedInputs(){
            [['companyData','companyDataAllSelected'],['productData','productChainDataAllSelected']].forEach(itemD => {
                let settedData = true;
                Object.entries(this.QRSettings[itemD[0]]).map(item => item[1]).forEach(element => {
                    if(!element) settedData = false;
                });
                this[itemD[1]] = settedData;
            })
        },
		setProductMediaFiles(e){
			this.productMediaFiles = e.target.files;
			
			this.images = [];
			let fileList = Array.prototype.slice.call(e.target.files);
			fileList.forEach(f => {

				if(!f.type.match("image.*")) {
				return;
				}

				let reader = new FileReader();
				let that = this;

				reader.onload = function (e) {
					that.images.push(e.target.result);
				}
				reader.readAsDataURL(f); 
			});
		},
		deleteMediaFromAdded(index){
			var newFileList = Array.from(this.productMediaFiles);
			newFileList = newFileList.splice(index, 1);
			this.images.splice(index, 1);
			this.productMediaFiles = newFileList;
		},
		deleteMediaPopupClose() {
			this.deleteMediaTarget = null;
			this.mediaPopup = false;
		},
		async uploadMedia(productId) {
			// const files = ev.target.files;
			const files = this.productMediaFiles;

			if(!files.length) return

			Object.values(files).forEach(async file => {
				let { name } = file;
				let s3File = await Storage.put(`media/${name}`, file);
				let input = {
					// productMediaId: this.companyData.id,
					productMediaId: productId,
					key: s3File.key
				}

				let newMedia = await API.graphql({
					query: createMedia,
					variables: { 
						input 
					}
				})

				let url = await Storage.get(s3File.key);
				this.media.push({
					key: s3File.key,
					id: newMedia.data.createMedia.id,
					url
				})
			})
		},
		dropMedia(ev) {
			const event = { target: ev.dataTransfer };

			this.images = [];
			let fileList = Array.prototype.slice.call(event.target.files);
			fileList.forEach(f => {
				if(!f.type.match("image.*")) {
					return;
				}

				let reader = new FileReader();
				let that = this;

				reader.onload = function (event) {
					that.images.push(event.target.result);
				}
				reader.readAsDataURL(f); 
			});

			// this.uploadMedia(event);
			this.productMediaFiles = event;
			this.dragState = false
		},

		farmLinkMenuOpen(id){
			if(this.farmLinkMenu === id || !id){
				this.farmLinkMenu = null;
			} else {
				this.farmLinkMenu = id;
			}
		},
		getProductName(id){
			return this.allProducts.find(p=>p.id==id)?.title
		},
		getProductFullObject(id){
			return this.allProducts.find(p=>p.id==id)
		},
		async initMap() {
			let payload={
				markerType:"product",
			}
			// this.$provider.gmap.setViewMode(true)
			this.$provider.gmap.createMap('product-map', payload, (res) => {
				this.setLocation(res)
			});
		},
		async setLocation(cords, setMapLocation = false) {
			// console.log('cords',cords)
			let query = Object.values(cords).join();
			const { data } = await this.$provider.positionStack.getReverse(query);
			if(data.length) {
				const {
					label,
					country,
					city,
					region,
					locality,
					county
				} = data[0];
				console.log("data[0]:", data[0]);
				let location={
					...cords,
					address:locality || county || label
				}
				// console.log("l",location);
				this.product.location=location
			}

			if(setMapLocation){
				this.$provider.gmap.setCords('product-map', cords);
				this.showSuggestions = false;
			}
		},

		async geocodeForward(address) {
			const { data } = await this.$provider.positionStack.getForward(address);

			if(data.length) {
				this.suggestionsLocations = data;
				this.showSuggestions = true;

				// const { 
				// 	latitude, 
				// 	longitude,
				// } = data[0];
				// this.$provider.gmap.setCords('product-map', { lat: latitude, lng: longitude })
				// this.setLocation({ lat: latitude, lng: longitude })
			}
		},
		async updateProductDataMethod(ev) {
			const target = ev.target;
			const value = target.value;
			const name = target.name;
			console.log('test',name,value)
			
			if(name === 'location') this.geocodeForward(value);
		},

		addParentProductOpen(){
			this.addParentProductsPopupInfo.show=true
			this.addParentProductsPopupInfo.products=this.allProducts
        },
		editParentProduct(product){
			this.addParentProductsPopupInfo.show=true
			this.addParentProductsPopupInfo.products=this.allProducts
			this.addParentProductsPopupInfo.product=product
        },
        addParentProductsClose(){
			this.addParentProductsPopupInfo.show=false
			this.addParentProductsPopupInfo.products=null
			this.addParentProductsPopupInfo.product=null
        },
        addParentProductsSubmit(product){
			let index = this.product.parent_products.findIndex(p=>p.id==product.id)
			console.log("heh",index);
			if(index==-1){
				this.product.parent_products.push(product)
			}else{		
				this.product.parent_products[index]=product
			}
			this.addParentProductsClose()
        },
        deleteParentProducts(id){
			this.product.parent_products.splice(this.product.parent_products.findIndex(p=>p.id==id),1)
        },
		opedOptions(ev) {
            ev.target.classList.toggle("active");
            ev.target.nextElementSibling.classList.toggle("active");
        },

        paddocksPopupOpen(type){
            this.paddocksPopup = {
                show: true,
                paddocks: [...this.paddocks].filter(item => (item.type === type)),
            };
        },
        paddocksPopupClose(){
            this.paddocksPopup = {
                show: false,
                paddocks: []
            };
        },
        submitPaddocksPopup(submitedPaddocks){
            this.product.paddocks = submitedPaddocks;
            this.paddocksPopupClose();
        },
		paddockItemRemove(id){
			this.product.paddocks.splice(this.product.paddocks.findIndex(item => item === id), 1);
		},

		async createProduct() {
			this.processing = true;

			const input = this.product;

			let devicesConfigs = [];

			this.QRSettings.deviceData.forEach(item => {
				devicesConfigs.push({
					device_type: null,
					// config: JSON.stringify(this.QRSettings.deviceData.find(itemF => itemF.deviceID === item))
					config: JSON.stringify(item)
				})
			})

			input.qr_settings={
				...this.QRSettings.companyData,
				...this.QRSettings.productData,
				actions_info: this.actions_info,
				devices: devicesConfigs,
			}
			const product = await API.graphql({
				query: createProduct,
				variables: { input }
			}).then(res =>res.data.createProduct);

			console.log('product:', product);

			await this.uploadMedia(product.id);

			if(this.categoryProductsId){
				await API.graphql({
					query: createProductsCategory,
					variables: {
						input: {
							productID: product.id,
							categoryID:  this.categoryProductsId           
						}
					}
				})
			}
			const requests = this.fieldArray('devices').map((deviceID) =>
                API.graphql({
                    query: updateDevice,
                    variables: {
                        input: {
                            id: deviceID,
               				productID: product.id
                        },
                    },
                }).then((res) => res.data.updateProduct)
            );
            await Promise.all(requests);
			await this.$store.dispatch("GET_DEVICES");
			this.product = product;
			this.$store.commit('ADD_ITEM', { field: 'products', item: product })
			this.processing = false;
			this.successAddPopup=true
		},

		getCell(obj,colNumber){
			let keys = Object.keys(obj)
			let size=keys.length
			let fullColsCount=Math.floor(size/3);
			let lastColsCount=size-fullColsCount*3
			let rows=[]
			for(let i=0;i<fullColsCount;i++){
				rows.push(keys.splice(0,3))
			}
			if(lastColsCount){
				rows.push(keys.splice(0,lastColsCount))
			}
			let col=[]
			rows.forEach(r=>{
				if(r[colNumber]){
					col.push(r[colNumber])
				}
			})
			return col
		},
	}
}
</script>

<style>
	.input-text:first-letter{
		text-transform:uppercase;
	}
</style>


